import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, catchError, of } from 'rxjs';

@Injectable()
export class FinishInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {}

  private interceptSurveyError(
    error: HttpErrorResponse
  ): Observable<HttpEvent<unknown>> | void {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 400) {
        const currentUrl = this.router.routerState.snapshot.url;
        const token = currentUrl.split('/')[1];
        const redirect = `/${token}/end`;
        this.router.navigateByUrl(redirect);

        return EMPTY;
      } else if (error.status === 404) {
        let redirect = '/';
        this.router.navigateByUrl(redirect);
        return EMPTY;
      }
    }
  }

  private interceptConsentError(
    error: HttpErrorResponse
  ): Observable<HttpEvent<unknown>> | void {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 400 || error.status === 500) {
        const currentUrl = this.router.routerState.snapshot.url;
        const token = currentUrl.split('/')[1];
        const redirect = `/${token}/end`;
        this.router.navigateByUrl(redirect);

        return EMPTY;
      } else if (error.status === 404) {
        let redirect = '/consent';
        this.router.navigateByUrl(redirect);
        return EMPTY;
      }
    }
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        let result;
        if (request.url.includes('user-consent')) {
          result = this.interceptConsentError(error);
        } else {
          result = this.interceptSurveyError(error);
        }

        return result || of();
      })
    );
  }
}
