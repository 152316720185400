/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddSurveyGenerated } from '../models/add-survey-generated';
import { BrowseGenerated } from '../models/browse-generated';
import { EditSurveyGenerated } from '../models/edit-survey-generated';
import { SurveyGenerated } from '../models/survey-generated';
import { SurveyListGenerated } from '../models/survey-list-generated';

@Injectable({
  providedIn: 'root',
})
export class SurveyServiceGenerated extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postSurvey
   */
  static readonly PostSurveyPath = '/survey';

  /**
   * Add survey.
   *
   * Add survey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSurvey()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSurvey$Response(params?: {
    context?: HttpContext
    body?: AddSurveyGenerated
  }
): Observable<StrictHttpResponse<SurveyGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyServiceGenerated.PostSurveyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SurveyGenerated>;
      })
    );
  }

  /**
   * Add survey.
   *
   * Add survey
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSurvey$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSurvey(params?: {
    context?: HttpContext
    body?: AddSurveyGenerated
  }
): Observable<SurveyGenerated> {

    return this.postSurvey$Response(params).pipe(
      map((r: StrictHttpResponse<SurveyGenerated>) => r.body as SurveyGenerated)
    );
  }

  /**
   * Path part for operation postSurveyBrowse
   */
  static readonly PostSurveyBrowsePath = '/survey/browse';

  /**
   * Browse survey.
   *
   * Browse survey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSurveyBrowse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSurveyBrowse$Response(params?: {
    context?: HttpContext
    body?: BrowseGenerated
  }
): Observable<StrictHttpResponse<Array<SurveyListGenerated>>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyServiceGenerated.PostSurveyBrowsePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SurveyListGenerated>>;
      })
    );
  }

  /**
   * Browse survey.
   *
   * Browse survey
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSurveyBrowse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSurveyBrowse(params?: {
    context?: HttpContext
    body?: BrowseGenerated
  }
): Observable<Array<SurveyListGenerated>> {

    return this.postSurveyBrowse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SurveyListGenerated>>) => r.body as Array<SurveyListGenerated>)
    );
  }

  /**
   * Path part for operation getSurveySurveyType
   */
  static readonly GetSurveySurveyTypePath = '/survey/{surveyType}';

  /**
   * Get survey.
   *
   * Get survey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSurveySurveyType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveySurveyType$Response(params: {
    surveyType: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<SurveyGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyServiceGenerated.GetSurveySurveyTypePath, 'get');
    if (params) {
      rb.path('surveyType', params.surveyType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SurveyGenerated>;
      })
    );
  }

  /**
   * Get survey.
   *
   * Get survey
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSurveySurveyType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSurveySurveyType(params: {
    surveyType: string;
    context?: HttpContext
  }
): Observable<SurveyGenerated> {

    return this.getSurveySurveyType$Response(params).pipe(
      map((r: StrictHttpResponse<SurveyGenerated>) => r.body as SurveyGenerated)
    );
  }

  /**
   * Path part for operation deleteSurveySurveyType
   */
  static readonly DeleteSurveySurveyTypePath = '/survey/{surveyType}';

  /**
   * Delete survey.
   *
   * Delete survey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSurveySurveyType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSurveySurveyType$Response(params: {
    surveyType: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyServiceGenerated.DeleteSurveySurveyTypePath, 'delete');
    if (params) {
      rb.path('surveyType', params.surveyType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete survey.
   *
   * Delete survey
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSurveySurveyType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSurveySurveyType(params: {
    surveyType: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteSurveySurveyType$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchSurveySurveyType
   */
  static readonly PatchSurveySurveyTypePath = '/survey/{surveyType}';

  /**
   * Edit survey.
   *
   * Edit survey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchSurveySurveyType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSurveySurveyType$Response(params: {
    surveyType: string;
    context?: HttpContext
    body?: EditSurveyGenerated
  }
): Observable<StrictHttpResponse<SurveyGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, SurveyServiceGenerated.PatchSurveySurveyTypePath, 'patch');
    if (params) {
      rb.path('surveyType', params.surveyType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SurveyGenerated>;
      })
    );
  }

  /**
   * Edit survey.
   *
   * Edit survey
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchSurveySurveyType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSurveySurveyType(params: {
    surveyType: string;
    context?: HttpContext
    body?: EditSurveyGenerated
  }
): Observable<SurveyGenerated> {

    return this.patchSurveySurveyType$Response(params).pipe(
      map((r: StrictHttpResponse<SurveyGenerated>) => r.body as SurveyGenerated)
    );
  }

}
