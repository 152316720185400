/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BrowseGenerated } from '../models/browse-generated';
import { ChangeStatusUserSurveyGenerated } from '../models/change-status-user-survey-generated';
import { CompleteUserSurveyGenerated } from '../models/complete-user-survey-generated';
import { ImportUserSurveyGenerated } from '../models/import-user-survey-generated';
import { StartUserSurveyGenerated } from '../models/start-user-survey-generated';
import { UserSurveyGenerated } from '../models/user-survey-generated';
import { UserSurveyListGenerated } from '../models/user-survey-list-generated';
import { UserSurveyQuestionGenerated } from '../models/user-survey-question-generated';

@Injectable({
  providedIn: 'root',
})
export class UserSurveyServiceGenerated extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSurvey
   */
  static readonly GetSurveyPath = '/user-survey/{token}';

  /**
   * Get Survey.
   *
   * Get a survey to be filled
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSurvey()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSurvey$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserSurveyGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserSurveyServiceGenerated.GetSurveyPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserSurveyGenerated>;
      })
    );
  }

  /**
   * Get Survey.
   *
   * Get a survey to be filled
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSurvey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSurvey(params: {
    token: string;
    context?: HttpContext
  }
): Observable<UserSurveyGenerated> {

    return this.getSurvey$Response(params).pipe(
      map((r: StrictHttpResponse<UserSurveyGenerated>) => r.body as UserSurveyGenerated)
    );
  }

  /**
   * Path part for operation saveSurveyAnswer
   */
  static readonly SaveSurveyAnswerPath = '/user-survey/{token}';

  /**
   * Save survey answer.
   *
   * Save a given answer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveSurveyAnswer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSurveyAnswer$Response(params: {
    token: string;
    context?: HttpContext
    body?: UserSurveyQuestionGenerated
  }
): Observable<StrictHttpResponse<UserSurveyGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserSurveyServiceGenerated.SaveSurveyAnswerPath, 'patch');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserSurveyGenerated>;
      })
    );
  }

  /**
   * Save survey answer.
   *
   * Save a given answer
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveSurveyAnswer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSurveyAnswer(params: {
    token: string;
    context?: HttpContext
    body?: UserSurveyQuestionGenerated
  }
): Observable<UserSurveyGenerated> {

    return this.saveSurveyAnswer$Response(params).pipe(
      map((r: StrictHttpResponse<UserSurveyGenerated>) => r.body as UserSurveyGenerated)
    );
  }

  /**
   * Path part for operation changeUserSurveyStatus
   */
  static readonly ChangeUserSurveyStatusPath = '/user-survey/{token}/change-status';

  /**
   * Change user survey status.
   *
   * Change the status of user survey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeUserSurveyStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeUserSurveyStatus$Response(params: {
    token: string;
    context?: HttpContext
    body?: ChangeStatusUserSurveyGenerated
  }
): Observable<StrictHttpResponse<UserSurveyGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserSurveyServiceGenerated.ChangeUserSurveyStatusPath, 'patch');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserSurveyGenerated>;
      })
    );
  }

  /**
   * Change user survey status.
   *
   * Change the status of user survey
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeUserSurveyStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeUserSurveyStatus(params: {
    token: string;
    context?: HttpContext
    body?: ChangeStatusUserSurveyGenerated
  }
): Observable<UserSurveyGenerated> {

    return this.changeUserSurveyStatus$Response(params).pipe(
      map((r: StrictHttpResponse<UserSurveyGenerated>) => r.body as UserSurveyGenerated)
    );
  }

  /**
   * Path part for operation postUserSurveyBrowse
   */
  static readonly PostUserSurveyBrowsePath = '/user-survey/browse';

  /**
   * Browse user survey.
   *
   * Browse user survey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserSurveyBrowse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserSurveyBrowse$Response(params?: {
    context?: HttpContext
    body?: BrowseGenerated
  }
): Observable<StrictHttpResponse<Array<UserSurveyListGenerated>>> {

    const rb = new RequestBuilder(this.rootUrl, UserSurveyServiceGenerated.PostUserSurveyBrowsePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserSurveyListGenerated>>;
      })
    );
  }

  /**
   * Browse user survey.
   *
   * Browse user survey
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postUserSurveyBrowse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserSurveyBrowse(params?: {
    context?: HttpContext
    body?: BrowseGenerated
  }
): Observable<Array<UserSurveyListGenerated>> {

    return this.postUserSurveyBrowse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserSurveyListGenerated>>) => r.body as Array<UserSurveyListGenerated>)
    );
  }

  /**
   * Path part for operation startSurvey
   */
  static readonly StartSurveyPath = '/user-survey/{token}/start';

  /**
   * Start survey.
   *
   * Start survey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSurvey()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startSurvey$Response(params: {
    token: string;
    context?: HttpContext
    body?: StartUserSurveyGenerated
  }
): Observable<StrictHttpResponse<UserSurveyGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserSurveyServiceGenerated.StartSurveyPath, 'post');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserSurveyGenerated>;
      })
    );
  }

  /**
   * Start survey.
   *
   * Start survey
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSurvey$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startSurvey(params: {
    token: string;
    context?: HttpContext
    body?: StartUserSurveyGenerated
  }
): Observable<UserSurveyGenerated> {

    return this.startSurvey$Response(params).pipe(
      map((r: StrictHttpResponse<UserSurveyGenerated>) => r.body as UserSurveyGenerated)
    );
  }

  /**
   * Path part for operation completeSurvey
   */
  static readonly CompleteSurveyPath = '/user-survey/{token}/complete';

  /**
   * Complete survey.
   *
   * Complete survey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeSurvey()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeSurvey$Response(params: {
    token: string;
    context?: HttpContext
    body?: CompleteUserSurveyGenerated
  }
): Observable<StrictHttpResponse<UserSurveyGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserSurveyServiceGenerated.CompleteSurveyPath, 'post');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserSurveyGenerated>;
      })
    );
  }

  /**
   * Complete survey.
   *
   * Complete survey
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `completeSurvey$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeSurvey(params: {
    token: string;
    context?: HttpContext
    body?: CompleteUserSurveyGenerated
  }
): Observable<UserSurveyGenerated> {

    return this.completeSurvey$Response(params).pipe(
      map((r: StrictHttpResponse<UserSurveyGenerated>) => r.body as UserSurveyGenerated)
    );
  }

  /**
   * Path part for operation putUserSurveyImport
   */
  static readonly PutUserSurveyImportPath = '/user-survey/import';

  /**
   * Import User Surveys.
   *
   * Import user surveys to be filled
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putUserSurveyImport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putUserSurveyImport$Response(params?: {
    context?: HttpContext
    body?: Array<ImportUserSurveyGenerated>
  }
): Observable<StrictHttpResponse<Array<UserSurveyGenerated>>> {

    const rb = new RequestBuilder(this.rootUrl, UserSurveyServiceGenerated.PutUserSurveyImportPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserSurveyGenerated>>;
      })
    );
  }

  /**
   * Import User Surveys.
   *
   * Import user surveys to be filled
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putUserSurveyImport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putUserSurveyImport(params?: {
    context?: HttpContext
    body?: Array<ImportUserSurveyGenerated>
  }
): Observable<Array<UserSurveyGenerated>> {

    return this.putUserSurveyImport$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserSurveyGenerated>>) => r.body as Array<UserSurveyGenerated>)
    );
  }

  /**
   * Path part for operation postUserSurveySendTobesent
   */
  static readonly PostUserSurveySendTobesentPath = '/user-survey/send-tobesent';

  /**
   * Send tobesent.
   *
   * Send surveys in to be sent status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserSurveySendTobesent()` instead.
   *
   * This method doesn't expect any request body.
   */
  postUserSurveySendTobesent$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserSurveyGenerated>>> {

    const rb = new RequestBuilder(this.rootUrl, UserSurveyServiceGenerated.PostUserSurveySendTobesentPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserSurveyGenerated>>;
      })
    );
  }

  /**
   * Send tobesent.
   *
   * Send surveys in to be sent status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postUserSurveySendTobesent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postUserSurveySendTobesent(params?: {
    context?: HttpContext
  }
): Observable<Array<UserSurveyGenerated>> {

    return this.postUserSurveySendTobesent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserSurveyGenerated>>) => r.body as Array<UserSurveyGenerated>)
    );
  }

}
