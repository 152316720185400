/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddUserConsentGenerated } from '../models/add-user-consent-generated';
import { SaveUserConsentGenerated } from '../models/save-user-consent-generated';
import { UserConsentGenerated } from '../models/user-consent-generated';

@Injectable({
  providedIn: 'root',
})
export class UserConsentServiceGenerated extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserConsent
   */
  static readonly GetUserConsentPath = '/user-consent/{token}';

  /**
   * Get User Consent.
   *
   * Get user consent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserConsent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserConsent$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserConsentGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserConsentServiceGenerated.GetUserConsentPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserConsentGenerated>;
      })
    );
  }

  /**
   * Get User Consent.
   *
   * Get user consent
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserConsent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserConsent(params: {
    token: string;
    context?: HttpContext
  }
): Observable<UserConsentGenerated> {

    return this.getUserConsent$Response(params).pipe(
      map((r: StrictHttpResponse<UserConsentGenerated>) => r.body as UserConsentGenerated)
    );
  }

  /**
   * Path part for operation deleteUserConsent
   */
  static readonly DeleteUserConsentPath = '/user-consent/project/{projectId}';

  /**
   * Delete User Consent By ProjectId.
   *
   * Delete User Consent by projectId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserConsent()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserConsent$Response(params: {
    projectId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserConsentServiceGenerated.DeleteUserConsentPath, 'delete');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete User Consent By ProjectId.
   *
   * Delete User Consent by projectId
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserConsent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserConsent(params: {
    projectId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteUserConsent$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation postUserConsent
   */
  static readonly PostUserConsentPath = '/user-consent';

  /**
   * Add User Consent.
   *
   * Add User Consent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserConsent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserConsent$Response(params?: {
    context?: HttpContext
    body?: AddUserConsentGenerated
  }
): Observable<StrictHttpResponse<UserConsentGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserConsentServiceGenerated.PostUserConsentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserConsentGenerated>;
      })
    );
  }

  /**
   * Add User Consent.
   *
   * Add User Consent
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postUserConsent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserConsent(params?: {
    context?: HttpContext
    body?: AddUserConsentGenerated
  }
): Observable<UserConsentGenerated> {

    return this.postUserConsent$Response(params).pipe(
      map((r: StrictHttpResponse<UserConsentGenerated>) => r.body as UserConsentGenerated)
    );
  }

  /**
   * Path part for operation postUserConsentResendSms
   */
  static readonly PostUserConsentResendSmsPath = '/user-consent/resend-sms';

  /**
   * Resend User Consent SMS.
   *
   * Resend User Consent SMS
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserConsentResendSms()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserConsentResendSms$Response(params?: {
    context?: HttpContext
    body?: AddUserConsentGenerated
  }
): Observable<StrictHttpResponse<UserConsentGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserConsentServiceGenerated.PostUserConsentResendSmsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserConsentGenerated>;
      })
    );
  }

  /**
   * Resend User Consent SMS.
   *
   * Resend User Consent SMS
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postUserConsentResendSms$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserConsentResendSms(params?: {
    context?: HttpContext
    body?: AddUserConsentGenerated
  }
): Observable<UserConsentGenerated> {

    return this.postUserConsentResendSms$Response(params).pipe(
      map((r: StrictHttpResponse<UserConsentGenerated>) => r.body as UserConsentGenerated)
    );
  }

  /**
   * Path part for operation saveUserConsent
   */
  static readonly SaveUserConsentPath = '/user-consent/{token}/save';

  /**
   * Save User Consent.
   *
   * Save User Consent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveUserConsent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveUserConsent$Response(params: {
    token: string;
    context?: HttpContext
    body?: SaveUserConsentGenerated
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserConsentServiceGenerated.SaveUserConsentPath, 'post');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save User Consent.
   *
   * Save User Consent
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveUserConsent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveUserConsent(params: {
    token: string;
    context?: HttpContext
    body?: SaveUserConsentGenerated
  }
): Observable<void> {

    return this.saveUserConsent$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
