/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddUserOtpRequestGenerated } from '../models/add-user-otp-request-generated';
import { UserOtpRequestGenerated } from '../models/user-otp-request-generated';
import { VerifyUserOtpRequestGenerated } from '../models/verify-user-otp-request-generated';

@Injectable({
  providedIn: 'root',
})
export class UserOtpRequestServiceGenerated extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addUserOtpRequest
   */
  static readonly AddUserOtpRequestPath = '/user-otp-request';

  /**
   * Add user OTP request.
   *
   * Add user OTP request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUserOtpRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUserOtpRequest$Response(params?: {
    context?: HttpContext
    body?: AddUserOtpRequestGenerated
  }
): Observable<StrictHttpResponse<UserOtpRequestGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserOtpRequestServiceGenerated.AddUserOtpRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserOtpRequestGenerated>;
      })
    );
  }

  /**
   * Add user OTP request.
   *
   * Add user OTP request
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addUserOtpRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUserOtpRequest(params?: {
    context?: HttpContext
    body?: AddUserOtpRequestGenerated
  }
): Observable<UserOtpRequestGenerated> {

    return this.addUserOtpRequest$Response(params).pipe(
      map((r: StrictHttpResponse<UserOtpRequestGenerated>) => r.body as UserOtpRequestGenerated)
    );
  }

  /**
   * Path part for operation verifyUserOtpRequest
   */
  static readonly VerifyUserOtpRequestPath = '/user-otp-request/verify';

  /**
   * Verify user OTP request.
   *
   * Verify user OTP request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyUserOtpRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyUserOtpRequest$Response(params?: {
    context?: HttpContext
    body?: VerifyUserOtpRequestGenerated
  }
): Observable<StrictHttpResponse<UserOtpRequestGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, UserOtpRequestServiceGenerated.VerifyUserOtpRequestPath, 'get');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserOtpRequestGenerated>;
      })
    );
  }

  /**
   * Verify user OTP request.
   *
   * Verify user OTP request
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verifyUserOtpRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyUserOtpRequest(params?: {
    context?: HttpContext
    body?: VerifyUserOtpRequestGenerated
  }
): Observable<UserOtpRequestGenerated> {

    return this.verifyUserOtpRequest$Response(params).pipe(
      map((r: StrictHttpResponse<UserOtpRequestGenerated>) => r.body as UserOtpRequestGenerated)
    );
  }

}
