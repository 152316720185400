/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddBusinessUnitGenerated } from '../models/add-business-unit-generated';
import { BusinessUnitGenerated } from '../models/business-unit-generated';

@Injectable({
  providedIn: 'root',
})
export class BusinessUnitServiceGenerated extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addBusinessUnit
   */
  static readonly AddBusinessUnitPath = '/business-unit';

  /**
   * Add new business unit.
   *
   * Add new business unit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBusinessUnit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBusinessUnit$Response(params?: {
    context?: HttpContext
    body?: AddBusinessUnitGenerated
  }
): Observable<StrictHttpResponse<BusinessUnitGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessUnitServiceGenerated.AddBusinessUnitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessUnitGenerated>;
      })
    );
  }

  /**
   * Add new business unit.
   *
   * Add new business unit
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addBusinessUnit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBusinessUnit(params?: {
    context?: HttpContext
    body?: AddBusinessUnitGenerated
  }
): Observable<BusinessUnitGenerated> {

    return this.addBusinessUnit$Response(params).pipe(
      map((r: StrictHttpResponse<BusinessUnitGenerated>) => r.body as BusinessUnitGenerated)
    );
  }

  /**
   * Path part for operation getBusinessUnit
   */
  static readonly GetBusinessUnitPath = '/business-unit/{businessUnitId}';

  /**
   * Get business unit.
   *
   * Get business unit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBusinessUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusinessUnit$Response(params: {
    businessUnitId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BusinessUnitGenerated>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessUnitServiceGenerated.GetBusinessUnitPath, 'get');
    if (params) {
      rb.path('businessUnitId', params.businessUnitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessUnitGenerated>;
      })
    );
  }

  /**
   * Get business unit.
   *
   * Get business unit
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBusinessUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusinessUnit(params: {
    businessUnitId: string;
    context?: HttpContext
  }
): Observable<BusinessUnitGenerated> {

    return this.getBusinessUnit$Response(params).pipe(
      map((r: StrictHttpResponse<BusinessUnitGenerated>) => r.body as BusinessUnitGenerated)
    );
  }

  /**
   * Path part for operation deleteBusinessUnit
   */
  static readonly DeleteBusinessUnitPath = '/business-unit/{businessUnitId}';

  /**
   * Delete business unit.
   *
   * Delete business unit
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBusinessUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBusinessUnit$Response(params: {
    businessUnitId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessUnitServiceGenerated.DeleteBusinessUnitPath, 'delete');
    if (params) {
      rb.path('businessUnitId', params.businessUnitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete business unit.
   *
   * Delete business unit
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteBusinessUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBusinessUnit(params: {
    businessUnitId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteBusinessUnit$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
