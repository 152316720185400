import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageContainerComponent } from '@core/layout/components/page-container/page-container.component';

const routes: Routes = [
  {
    path: '',
    component: PageContainerComponent,
    children: [
      {
        path: 'consent',
        loadChildren: () =>
          import('./features/consent/consent.module').then(
            m => m.ConsentModule
          ),
        title: 'Consenso Privacy',
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/survey/survey.module').then(m => m.SurveyModule),
        title: 'Questionario',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
