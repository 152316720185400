import { Component } from '@angular/core';
import { ThemeService } from '@services/theme.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent {
  title = 'Survey';
  theme$: Observable<string>;

  constructor(readonly themeService: ThemeService) {
    this.theme$ = themeService.theme$;
  }
}
